import React from 'react';
import { format } from 'date-fns';
import { Claim } from '../helpers/Interfaces';

type Props = {
  marker: Claim;
};

const formatLossDate = (date: any) => {
  if (!date) return null;
  const parsedDate = new Date(date);
  return format(parsedDate, 'MMMM dd, yyyy');
};

const InfoWindowComponent: React.FC<Props> = ({ marker }) => {
  return (
    <div className="info-window">
      <div className="info-header d-flex justify-content-between">
        <h4>{marker.claimNumber} ({marker.lossType})</h4>
      </div>
      <div className="info-row d-flex">
        <i className="fas fa-map-marker-alt text-secondary mr-2"></i>
        <div>{marker.projAddr}</div>
      </div>
      {marker.lossDate && (
        <div className="info-row">
          <label className="info-label">Loss Date: </label> {formatLossDate(marker.lossDate)}
        </div>
      )}
      {marker?.phases?.[0]?.phasePm || marker.pmnameClaim ? (
        <div className="info-row">
          <label className="info-label">Project Manager: </label> {marker?.phases?.[0]?.phasePm ?? marker.pmnameClaim}
        </div>
      ) : null}
      {marker.propertyManager && (
        <div className="info-row">
          <label className="info-label">Property Manager: </label> {marker.propertyManager}
        </div>
      )}
      {marker.adjuster && (
        <div className="info-row">
          <label className="info-label">Adjuster: </label> {marker.adjuster}
        </div>
      )}
    </div>
  );
};

export default InfoWindowComponent;
