import React, { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FaCaretRight, FaFileExcel, FaFilter, FaRegBuilding } from 'react-icons/fa';
import useRestClient, { ClientType } from '../helpers/AxiosClient';
import Map from '../components/Map';
import FilterModal from '../components/FilterModal';
import MarkerIcon from '../components/Marker.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import { GOOGLE_API_KEY, DISTANCES, CLAIM_TYPES } from '../helpers/Const';
import { exportToExcel, isEmpty } from '../helpers/genericMethods';
import { Address, Claim, Branch, InsuranceCompany, FilterForm, PhaseOption } from '../helpers/Interfaces';

const SearchByAddressTab = () => {
    const [address, setAddress] = useState<Address>();
    const [markers, setMarkers] = useState<Claim[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBranches, setSelectedBranches] = useState<Branch[]>([]);
    const [selectedPhases, setSelectedPhases] = useState<PhaseOption[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<InsuranceCompany[]>([]);
    const [invalidFilter, setInvalidFilter] = useState(false);
    const client = useRestClient(ClientType.JSON);
    const { t } = useTranslation();
    const initFilter = {
        selectedDistance: '',
        selectedRegion: null,
        lossCat: undefined,
        cat: undefined,
        PM: undefined,
        startDate: undefined,
        endDate: undefined,
        branches: [] as Branch[],
        companies: [] as InsuranceCompany[],
        phases: [] as PhaseOption[],
    } as FilterForm;

    const [filterForm, setFilterForm] = useState<FilterForm>(initFilter);

    const handleFormChange = (field: string, value: any) => {
        setFilterForm(prevForm => ({ ...prevForm, [field]: value }));
    };

    const handlePlaceSelect = (place: any) => {
        const postal = place?.address_components?.find((component: any) => component?.types?.includes('postal_code'));
        setAddress({
            postal: postal?.long_name,
            formattedAddress: place?.formatted_address,
            location: {
                lat: +place?.geometry?.location?.lat(),
                lng: +place?.geometry?.location?.lng()
            }
        });
    };

    const handleExportClick = () => {
        exportToExcel(markers);     
    };

    const applyFilter = async () => {
        if (!address) {
            toast.error(t('errorLocationRequired'));
            return;
        }

        if (!address.postal) {
            toast.error(t('postalIsRequired'));
            return;
        }

        if (!filterForm.selectedDistance) {
            toast.error(t('distanceIsRequired'));
            return;
        }

        const isRegionOnlySelected =
        !isEmpty(filterForm.selectedRegion) &&
        isEmpty(filterForm.PM) &&
        isEmpty(filterForm.companies) &&
        isEmpty(filterForm.branches) &&
        isEmpty(filterForm.phases);
        
        if (isRegionOnlySelected) {
            setInvalidFilter(true);
            return;
        }

        setInvalidFilter(false);

        try {
            setIsLoading(true);

            const response: AxiosResponse<Claim[]> = await client.post(
                `/common/claims/search/address?postal=${address?.postal}&latitude=${address?.location?.lat}&longitude=${address?.location?.lng}&distance=${filterForm.selectedDistance}`,
                {
                    ...filterForm,
                }
            );

            const claims = response.data;
            setMarkers(claims);

            const modalElement = document.getElementById('filterModal');
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            modalInstance.hide();
        } catch (err) {
            console.log(err);
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };

    const clearFilter = async () => {        
        const resetFilters = {...filterForm,
            startDate: undefined,
            endDate: undefined,
            selectedRegion: null,
            cat: undefined,
            PM: undefined,
            branches: [] as Branch[],
            companies: [] as InsuranceCompany[],
            phases: [] as PhaseOption[],
        };

        setFilterForm(resetFilters);
        setSelectedBranches([]);
        setSelectedPhases([]);
        setSelectedCompanies([]);
        setInvalidFilter(false);

        setIsLoading(true);
        
        try 
        {
            if(address && address.postal && filterForm.selectedDistance) 
            {
                const response: AxiosResponse<Claim[]> = await client.post(
                    `/common/claims/search/address?postal=${address?.postal}&latitude=${address?.location?.lat}&longitude=${address?.location?.lng}&distance=${filterForm.selectedDistance}`,
                    {
                        ...resetFilters,
                    }
                );
                const claims = response.data;
                setMarkers(claims);
            }            
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchClick = useCallback(async (address?: Address) => {
        if (!address?.postal) {
            toast.error(t('postalIsRequired'));
            return;
        }
        if (!filterForm.selectedDistance) {
            toast.error(t('distanceIsRequired'));
            return;
        }

        setIsLoading(true);
        try {
            const response: AxiosResponse<Claim[]> = await client.post(
                `/common/claims/search/address?postal=${address.postal}&latitude=${address.location?.lat}&longitude=${address.location?.lng}&distance=${filterForm.selectedDistance}`,
                {
                    ...filterForm,
                }
            );
            setMarkers(response.data);
        } catch (err) {
            toast.error(`${t('errorFetch')} ${err}`);
        } finally {
            setIsLoading(false);
        }
    }, [client, filterForm, t]);

    return (
        <div className="p-3">
            <div className="d-flex align-items-center mb-2">
                <Autocomplete
                    apiKey={GOOGLE_API_KEY}
                    onPlaceSelected={handlePlaceSelect}
                    options={{ types: ['address'], componentRestrictions: { country: 'ca' } }}
                    style={{ width: '40%', marginRight: '0.5rem' }}
                    className="form-control"
                />
                <select
                    value={filterForm.selectedDistance}
                    onChange={(e) => handleFormChange('selectedDistance', e.target.value)}
                    className="form-control"
                    style={{ marginRight: '0.5rem', width: 'auto' }}
                >
                    <option value="" disabled hidden>{t('distance')}</option>
                    {DISTANCES.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <select
                    value={filterForm.lossCat ?? ''}
                    onChange={(e) => handleFormChange('lossCat', e.target.value)}
                    disabled={!address || !filterForm.selectedDistance}
                    className="form-control"
                    style={{ marginRight: '0.5rem', width: 'auto' }}
                >
                    <option value="" disabled hidden>{t('lossCat')}</option>
                    {CLAIM_TYPES.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#filterModal">
                    <FaFilter />
                </button>
                <button className="btn btn-primary" onClick={() => handleSearchClick(address)}>{t('searchBtn')}</button>
                {markers && markers.length > 0 && (
                    <button className="btn btn-primary d-flex align-items-center" onClick={handleExportClick}>
                        <FaFileExcel /> {t('export')}
                    </button>
                )}
                {/* Legend */}
                <div className="mb-3">
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ background: 'conic-gradient(#ea4335, #1a73e8, #4285f4, #34a853, #fbbc04)' }}></div>
                        <span className="ms-2">{t('mainAddress')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#9166fb' }}></div>
                        <span className="ms-2">{t('ctClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#ff9015' }}></div>
                        <span className="ms-2">{t('cTInspectedClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <FaRegBuilding />
                        <span className="ms-2">{t('commercialClaims')}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{ height: 'calc(100vh - 70px)' }}>
                {address && (
                    <div style={{ flex: '1 1 20%', paddingRight: '0.5rem' }}>
                        <img src={MarkerIcon} alt="Marker Icon" style={{ width: '20px', height: '20px' }} />
                        <span className="flex-grow-1 mx-2 small">{address.formattedAddress}</span>
                        <FaCaretRight className="text-secondary" style={{ minWidth: '20px' }} />
                    </div>
                )}
                <div style={{ flex: '3 1 80%' }}>
                    {isLoading ? (
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : address ? (
                        <Map center={address.location} zoom={15} claimMarkers={markers} />
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src="assets/images/FirstOnsiteLogo.png" alt="No Search" />
                        </div>
                    )}
                </div>
            </div>

            <div className="p-3">
                <FilterModal
                    filterForm={filterForm}
                    onFilterChange={handleFormChange}
                    clearFilter={clearFilter}
                    applyFilter={applyFilter}
                    selectedBranches={selectedBranches}
                    setSelectedBranches={setSelectedBranches}
                    selectedPhases={selectedPhases}
                    setSelectedPhases={setSelectedPhases}
                    selectedCompanies={selectedCompanies}
                    setSelectedCompanies={setSelectedCompanies}
                    invalidFilter={invalidFilter}
                    setInvalidFilter={setInvalidFilter}
                />
            </div>

            <ToastContainer />
        </div>
    );
};

export default SearchByAddressTab;
