import * as XLSX from 'xlsx';
import { Claim } from '../helpers/Interfaces';


export const exportToExcel = (markers: Claim [] | undefined) => {
    if (markers?.length) {
        const formattedData = markers.flatMap(item => {
            // If there are no phases, return a single row with claim details only
            if (!item.phases?.length) {
                return [{
                    'Claim Number': item.claimNumber,
                    'Claim Project Manager': item.pmnameClaim,
                    'Adjuster': item.adjuster,
                    'Insurance carrier': item.insurer,
                    'Loss Type': item.lossType,
                    'Loss Date': item.lossDate,
                    'Project Address': item.projAddr,
                    'Project City': item.projCity,
                    'Project Province': item.projProv,
                    'Status': item.status,
                    'Inspected': item.isInspected ? "Yes" : "No",
                    'Phase Project Manager': '', // Leave empty if no phase data
                    'Phase Code': '',
                    'Phase Opened Date': ''
                }];
            }
            
            // Map each phase to a new row with claim and phase details
            return item.phases.map(phase => ({
                'Claim Number': item.claimNumber,
                'Claim Project Manager': item.pmnameClaim,
                'Adjuster': item.adjuster,
                'Insurance carrier': item.insurer,
                'Loss Type': item.lossType,
                'Loss Date': item.lossDate,
                'Project Address': item.projAddr,
                'Project City': item.projCity,
                'Project Province': item.projProv,
                'Status': item.status,
                'Inspected': item.isInspected ? "Yes" : "No",
                'Phase Project Manager': phase.phasePm,
                'Phase Code': phase.phaseCode,
                'Phase Opened Date': phase.dateOpened ?? ''
            }));
        });
    
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        if (worksheet['!ref']) {
            const range = XLSX.utils.decode_range(worksheet['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
                if (!worksheet[cellAddress]) continue;
                worksheet[cellAddress].s = { font: { bold: true } };
            }
        }
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "ClaimTrakClaims.xlsx");
    }          
};

export const isEmpty = (value: any) => {
    return (
        value === undefined ||
        value === null ||
        value === '' ||
        (Array.isArray(value) && value.length === 0)
    );
};