import React, { FC, useState, useEffect } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { ClaimType } from '../helpers/Const';
import { renderToStaticMarkup } from 'react-dom/server';
import { Claim, FileClaim } from '../helpers/Interfaces';
import InfoWindowComponent from './InfoWindowComponent';
import FileInfoWindowComponent from './FileInfoWindowComponent';
import { FaRegBuilding } from 'react-icons/fa';
import './InfoWindowComponent.css';

interface Location {
  address?: string;
  lat: number;
  lng: number;
  name?: string;
}

type Props = {
  center?: Location;
  zoom?: number;
  icon?: string;
  claimMarkers?: Claim[];
  fileMarkers?: FileClaim[];
  selectedLocation?: FileClaim;
};

const containerStyle = {
  width: '100%',
  height: '800px',
};

const customMapStyles = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

const getFaCommercialIconUrl = (color: string) => {
  const iconMarkup = renderToStaticMarkup(<FaRegBuilding style={{ color: color, fontSize: '30px' }} />);
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconMarkup)}`;
};

const Map: FC<Props> = ({ center, zoom, icon, claimMarkers, fileMarkers, selectedLocation }) => {
  const [selectedMarker, setSelectedMarker] = useState<Claim | null>(null);
  const [selectedFileMarker, setSelectedFileMarker] = useState<FileClaim | null>(null);
  const [selectedCenter, setSelectedCenter] = useState<Location | null>(null);

  useEffect(() => {
    setSelectedFileMarker(selectedLocation || null);
  }, [selectedLocation]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={{ styles: customMapStyles }}
      center={center}
      zoom={zoom}
    >
      {center && (
        <MarkerF
          position={center}
          icon={{
            url: require('./Marker.svg').default,
            scaledSize: new google.maps.Size(40, 40),
          }}
          onMouseOver={() => setSelectedCenter(center)}
          onClick={() => setSelectedCenter(center)}
          onMouseOut={() => setSelectedCenter(null)}
        >
          {selectedCenter === center && selectedCenter?.address && selectedCenter?.name && (
            <InfoWindowF position={{ lat: center.lat, lng: center.lng }} onCloseClick={() => setSelectedCenter(null)}>
              <FileInfoWindowComponent marker={center}></FileInfoWindowComponent>
            </InfoWindowF>
          )}
        </MarkerF>
      )}
      {fileMarkers?.map((marker, index) => (
        <MarkerF
          key={index}
          position={{ lat: marker.lat, lng: marker.lng }}
          icon={{
            url: marker === center ? require('./Marker.svg').default : "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            scaledSize: new google.maps.Size(40, 40),  // Ensure consistent size for SVG markers
          }}
          onMouseOver={() => setSelectedFileMarker(marker)}
          onClick={() => setSelectedFileMarker(marker)}
          onMouseOut={() => setSelectedFileMarker(null)}
        >
          {selectedFileMarker === marker && (
            <InfoWindowF position={{ lat: marker.lat, lng: marker.lng }} onCloseClick={() => setSelectedFileMarker(null)}>
              <FileInfoWindowComponent marker={marker}></FileInfoWindowComponent>
            </InfoWindowF>
          )}
        </MarkerF>
      ))}
      {claimMarkers?.map((marker, index) => {
        let markerIcon = "http://maps.google.com/mapfiles/ms/icons/purple-dot.png";

        if (marker.lossCat === ClaimType.Commercial.toString()) {
          markerIcon = getFaCommercialIconUrl(marker.isInspected ? '#ff9015' : '#9166fb');
        } else if (marker.isInspected) {
          markerIcon = "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
        }
      
        return (
        <MarkerF
          key={index}
          position={{ lat: marker.lossAddressLat, lng: marker.lossAddressLon }}
          icon={{
            url: markerIcon,
          }}
          onMouseOver={() => setSelectedMarker(marker)}
          onClick={() => setSelectedMarker(marker)}
          onMouseOut={() => setSelectedMarker(null)}
        >
          {selectedMarker === marker && (
            <InfoWindowF position={{ lat: marker.lossAddressLat, lng: marker.lossAddressLon }} onCloseClick={() => setSelectedMarker(null)}>
              <InfoWindowComponent marker={marker} />
            </InfoWindowF>
          )}
        </MarkerF>
        );
      })}
    </GoogleMap>
  );
};

export default Map;
