import React, { Dispatch, SetStateAction, useState, useCallback, useEffect } from 'react';

import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';
import { Cat, Region, PM, Branch, PhaseOption, InsuranceCompany, FilterForm } from '../helpers/Interfaces';
import useRestClient, { ClientType } from '../helpers/AxiosClient';

interface FilterModalProps {
    filterForm: FilterForm;
    onFilterChange: (field: string, value: any) => void;
    invalidFilter: boolean;
    setInvalidFilter: Dispatch<SetStateAction<boolean>>;
    clearFilter: () => void;
    applyFilter: () => void;
    selectedBranches: Branch[]; 
    selectedPhases: PhaseOption[]; 
    setSelectedBranches: Dispatch<SetStateAction<Branch[]>>;
    setSelectedPhases: Dispatch<SetStateAction<PhaseOption[]>>;
    selectedCompanies: InsuranceCompany[]; 
    setSelectedCompanies: Dispatch<SetStateAction<any[]>>;
}

const FilterModal: React.FC<FilterModalProps> = ({
    filterForm,
    invalidFilter,
    setInvalidFilter,
    onFilterChange,
    clearFilter,
    applyFilter,
    selectedBranches,
    selectedPhases,
    setSelectedBranches,
    setSelectedPhases,
    selectedCompanies, 
    setSelectedCompanies,
}) => {
    const { t } = useTranslation();
    const [catTags, setCatTags] = useState<Cat[]>([]);
    const [regionList, setRegionList] = useState<Region[]>([]);
    const [pmList, setPmList] = useState<PM[]>([]);
    const [branchList, setBranchList] = useState<Branch[]>([]);
    const [phaseList, setPhaseList] = useState<PhaseOption[]>([]);
    const [companyList, setCompanyList] = useState<InsuranceCompany[]>([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const client = useRestClient(ClientType.JSON);

    const fetchInitialData = useCallback(async () => {
        try {
            setIsLoading(true);
            const [catsResponse, regionsResponse] = await Promise.all([
                client.get('/common/cats'),
                client.get('/common/regions?isTest=false')
            ]);
            setCatTags(catsResponse.data);
            setRegionList(regionsResponse.data);
        } catch (error) {
            console.error("Error fetching initial data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [client]);

    useEffect(() => {
        const modalElement = document.getElementById('filterModal');
        if (modalElement) {
            const handleModalShow = () => fetchInitialData();
            modalElement.addEventListener('show.bs.modal', handleModalShow);
            return () => modalElement.removeEventListener('show.bs.modal', handleModalShow);
        }
    }, [fetchInitialData]);

    const fetchPmList = useCallback(async (regionId: number) => {
        setIsLoading(true);
        try {
            const response = await client.get(`/common/project-managers?regionId=${regionId}`);
            setPmList(response.data);
        } catch (error) {
            setError('errorFetchPm');
        } finally{
            setIsLoading(false);
        }
    }, [client]);

    const fetchPhaseList = useCallback(async (regionId: number) => {
        setIsLoading(true);
        try {
            const response = await client.get(`/phases?regionId=${regionId}`);
            setPhaseList(response.data);
        } catch (error) {
            setError('errorFetchBranch');
        } finally{
            setIsLoading(false);
        }
    }, [client]);

    const fetchBranchList = useCallback(async (regionId: number) => {
        setIsLoading(true);
        try {
            const response = await client.get(`/common/branches?enabled=true&regionId=${regionId}`);
            const sortedBranchList = response.data.sort((a: any, b: any) => a.branchName.localeCompare(b.branchName));
        
            setBranchList(sortedBranchList);
        } catch (error) {
            setError('errorFetchBranch');
        } finally{
            setIsLoading(false);
        }
    }, [client]);

    const fetchCompanyList = useCallback(async (regionId: number) => {
        setIsLoading(true);
        try {
            const response = await client.get(`/common/companies?regionId=${regionId}`);
            setCompanyList(response.data);
        } catch (error) {
            setError('errorFetchCompany');
        } finally{
            setIsLoading(false);
        }
    }, [client]);

    const branchOptions = branchList.map(branch => ({
        branchCode: branch.branchCode,
        branchName: branch.branchName,
    }));

    const phaseOptions = phaseList.map(phase => ({
        phaseCode: phase.id,
        phaseName: phase.value,
    }));

    const companyOptions = companyList.map(company => ({
        companyId: company.coCode,
        companyName: company.company,
    }));

    const handlePhaseChange = (selectedList: any) => {
        setSelectedPhases(selectedList);
        const phaseCodes = selectedList.map((phase: any) => phase.phaseCode);
        onFilterChange('phases', phaseCodes);
    };

    const handleBranchChange = (selectedList: Branch[]) => {
        setSelectedBranches(selectedList);
        const branchCodes = selectedList.map(branch => branch.branchCode);
        onFilterChange('branches', branchCodes);
    };

    const handleCompanyChange = (selectedList: any[]) => {
        setSelectedCompanies(selectedList);
        const companyCodes = selectedList.map(company => company.companyId);
        onFilterChange('companies', companyCodes);
    };

    const handleFilterCatChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onFilterChange('cat', +e.target.value);
    }

    const handleFilterPmChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onFilterChange('PM', e.target.value);
    }

    const handleFilterRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const regionId = e.target.value;
        const region = regionList.find(r => r.regionId === +regionId);

        if(region) {
            onFilterChange('selectedRegion', region);
        }

        onFilterChange('branches', null);
        onFilterChange('PM', '');
        onFilterChange('companies', null);
        onFilterChange('phases', null);
        setSelectedPhases([]);
        setSelectedBranches([]);
        setSelectedCompanies([]);
        fetchPmList(Number(regionId));
        fetchBranchList(Number(regionId));
        fetchPhaseList(Number(regionId));
        fetchCompanyList(Number(regionId));
    };

    return (
        <div className="modal fade" id="filterModal" tabIndex={-1} aria-labelledby="filterModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="filterModalLabel">{t('filterTitle')}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setInvalidFilter(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                    {isLoading ? (
                            <div style={{height: '100%', minHeight: '500px'}} className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border" role="status">
                                </div>
                            </div>
                        ) : (
                        <>
                            <div className="mb-3">
                                <label htmlFor="filterStartDate" className="form-label">{t('startDate')}</label>
                                <input
                                    type="date"
                                    id="filterStartDate"
                                    className="form-control"
                                    value={filterForm.startDate}
                                    onChange={(e) => onFilterChange('startDate', e.target.value ? e.target.value : undefined)}
                                />
                                <label htmlFor="filterEndDate" className="form-label mt-2">{t('endDate')}</label>
                                <input
                                    type="date"
                                    id="filterEndDate"
                                    className="form-control"
                                    value={filterForm.endDate}
                                    onChange={(e) =>  onFilterChange('endDate', e.target.value ? e.target.value : undefined)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="filterValue" className="form-label">{t('filterByCat')}</label>
                                <select
                                    id="filterValue"
                                    className="form-select"
                                    value={filterForm.cat}
                                    onChange={handleFilterCatChange}
                                >
                                    <option value="">{t('selectCatTag')}</option>
                                    {catTags.map(cat => (
                                        <option key={cat.id} value={cat.id}>{cat.catName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 d-flex align-items-center">
                                <div className="me-3">
                                    <label htmlFor="filterRegion" className="form-label">{t('filterByRegion')}</label>
                                    <select
                                        id="filterRegion"
                                        className="form-select"
                                        value={filterForm.selectedRegion?.regionId}
                                        onChange={e => handleFilterRegionChange(e)}
                                    >
                                        <option value="">{t('selectRegion')}</option>
                                        {regionList.map(region => (
                                            <option key={region.regionId} value={region.regionId}>{region.regionName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="filterPm" className="form-label">{t('filterByPm')}</label>
                                    <select
                                        id="filterPm"
                                        className="form-select"
                                        value={filterForm.PM}
                                        onChange={handleFilterPmChange}
                                        disabled={!filterForm.selectedRegion}
                                    >
                                        <option value="">{t('selectRegionFirst')}</option>
                                        {pmList.map(pm => (
                                            <option key={pm.id} value={pm.id}>{pm.firstName} {pm.lastName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="filterPhase" className="form-label">{t('filterByPhase')}</label>
                                <Multiselect
                                    id="filterPhase"
                                    options={phaseOptions}
                                    selectedValues={selectedPhases}
                                    onSelect={handlePhaseChange}
                                    onRemove={handlePhaseChange}
                                    displayValue="phaseName"
                                    placeholder={t('selectRegionFirst')}
                                    disable={!filterForm.selectedRegion}
                                    showCheckbox
                                    isObject
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="filterBranch" className="form-label">{t('filterByBranch')}</label>
                                <Multiselect
                                    id="filterBranch"
                                    options={branchOptions}
                                    selectedValues={selectedBranches}
                                    onSelect={handleBranchChange}
                                    onRemove={handleBranchChange}
                                    displayValue="branchName"
                                    placeholder={t('selectRegionFirst')}
                                    disable={!filterForm.selectedRegion}
                                    showCheckbox
                                    isObject
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="filterCompany" className="form-label">{t('filterByCompany')}</label>
                                <Multiselect
                                    id="filterCompany"
                                    options={companyOptions}
                                    selectedValues={selectedCompanies}
                                    onSelect={handleCompanyChange}
                                    onRemove={handleCompanyChange}
                                    displayValue="companyName"
                                    placeholder={t('selectRegionFirst')}
                                    disable={!filterForm.selectedRegion}
                                    showCheckbox
                                    isObject
                                />
                            </div>
                            {invalidFilter && <div className="alert alert-warning">{t('pMNotSelected')}</div>}
                        </>)}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={clearFilter} data-bs-dismiss="modal">
                            {t('clearFilter')}
                        </button>
                        <button type="button" className="btn btn-primary" onClick={applyFilter}>
                            {t('applyFilter')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;
