import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import * as XLSX from 'xlsx';
import { FaMapMarkerAlt, FaCaretRight, FaFilter, FaFileExcel, FaRegBuilding } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import './SearchByAddressTab.css';
import Map from '../components/Map';
import FilterModal from '../components/FilterModal';
import MarkerIcon from '../components/Marker.svg';
import useRestClient, { ClientType } from '../helpers/AxiosClient';
import { GOOGLE_API_KEY, DISTANCES, BATCH_SIZE, CLAIM_TYPES } from '../helpers/Const';
import { Claim, FileClaim, Location, ExcelRow, Branch, PhaseOption, InsuranceCompany, FilterForm } from '../helpers/Interfaces';
import { exportToExcel, isEmpty } from '../helpers/genericMethods';

const BulkUploadTab: React.FC = () => {
    const [mainAddress, setMainAddress] = useState<Location | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [markers, setMarkers] = useState<FileClaim[]>([]);
    const [filteredMarkers, setFilteredMarkers] = useState<FileClaim[]>([]);
    const [paginatedMarkers, setPaginatedMarkers] = useState<FileClaim[]>([]);
    const [claimMarkers, setClaimMarkers] = useState<Claim[]>();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState<FileClaim | undefined>();
    const [showActiveJobs, setShowActiveJobs] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [markerToConfirm, setMarkerToConfirm] = useState<FileClaim | null>(null);
    const [selectedBranches, setSelectedBranches] = useState<Branch[]>([]);
    const [selectedPhases, setSelectedPhases] = useState<PhaseOption[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<InsuranceCompany[]>([]);

    const initFilter = {
        selectedDistance: '',
        selectedRegion: null,
        lossCat: undefined,
        cat: undefined,
        PM: undefined,
        startDate: undefined,
        endDate: undefined,
        branches: [] as Branch[],
        companies: [] as InsuranceCompany[],
        phases:[] as PhaseOption[],
    } as FilterForm;

    const [filterForm, setFilterForm] = useState<FilterForm>(initFilter);

    const handleFormChange = (field: string, value: any) => {
        setFilterForm(prevForm => ({ ...prevForm, [field]: value }));
    };

    const [invalidFilter, setInvalidFilter] = useState(false);
    const client = useRestClient(ClientType.JSON);
    const { t } = useTranslation();

    const processExcelData = (data: string): { address: string; name: string }[] => {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet: ExcelRow[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 }) as ExcelRow[];

        const headerRowIndex = worksheet.findIndex(row => row.includes('ADDRESS') && row.includes('TOWN'));

        if (headerRowIndex === -1) {
            throw new Error(t('errorHeadersNotFound'));
        }

        const addressColIndex = worksheet[headerRowIndex].indexOf('ADDRESS');
        const townColIndex = worksheet[headerRowIndex].indexOf('TOWN');
        const nameIndex = worksheet[headerRowIndex].indexOf('NAME');

        const addresses = worksheet.slice(headerRowIndex + 1).map(row => ({
            address: `${row[addressColIndex]} ${row[townColIndex]}`,
            name: row[nameIndex]
        }));
        return addresses;
    };

    const geocodeAddress = async (address: string): Promise<Location | null> => {
        const apiKey = GOOGLE_API_KEY;
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`);
            const location = response.data.results[0]?.geometry?.location;
            const postal = response.data.results[0]?.address_components?.find((component: any) => component?.types?.includes('postal_code'))?.long_name;
            if (location) {
                return { address, lat: location.lat, lng: location.lng, postal };
            }
        } catch (error) {
            toast.error(`${t('errorGeocoding')} ${address}: ${error}`);
        }
        return null;
    };

    const calculateDistance = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLng = (lng2 - lng1) * (Math.PI / 180);
        const a = 
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    };

    const handleFileProcessed = async (data: string) => {
        const addresses = processExcelData(data);
        const geocodedAddresses = await Promise.all(addresses.map(async addr => {
            const location = await geocodeAddress(addr.address);
            if (location) {
                return { ...location, name: addr.name };
            }
            return null;
        }));
        const validMarkers = geocodedAddresses.filter(marker => marker !== null) as FileClaim[];

        if (validMarkers.length > 0) {
            const main = validMarkers[0];
            setMainAddress(main);

            validMarkers.sort((a, b) => {
                const distanceA = calculateDistance(main.lat, main.lng, a.lat, a.lng);
                const distanceB = calculateDistance(main.lat, main.lng, b.lat, b.lng);
                return distanceA - distanceB;
            });
        }

        setMainAddress(validMarkers[0]);
        setMarkers(validMarkers);
        setIsLoading(false);
        setCurrentPage(1);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setFileUploaded(false);
        handleFormChange('selectedDistance', '');

        const file = e.target.files?.[0];
        if (file) {
            const fileType = file.type;
            if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "application/vnd.ms-excel") {
                setFileUploaded(true); 
                const reader = new FileReader();
                reader.onload = (event) => {
                    const data = event.target?.result as string;
                    handleFileProcessed(data);
                };
                reader.readAsBinaryString(file);
            } else {
                setIsLoading(false);
                toast.error(t('errorInvalidFileType'));
            }
        } else {
            setIsLoading(false);
        }
    };

    const handleDistanceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        handleFormChange('selectedDistance', event.target.value);
    };

    const handleExportClick = () => {
        exportToExcel(claimMarkers);       
    };

    const handleSearchClick = useCallback(async () => {
        if (!fileUploaded) {
            toast.error(t('fileIsRequired'));
            return;
        }
        if (!filterForm.selectedDistance) {
            toast.error(t('distanceIsRequired'));
            return;
        }

        setIsLoading(true);
        try {
            const response: AxiosResponse<Claim[]> = await client.post(
                `/common/claims/search/address?postal=${mainAddress?.postal}&latitude=${mainAddress?.lat}&longitude=${mainAddress?.lng}&distance=${filterForm.selectedDistance}`,
                {
                    ...filterForm,
                }
            );
            const claims = response.data;
            setClaimMarkers(claims);

            // Highlight items in paginatedMarkers that are also in claimMarkers
            const updatedMarkers = markers.map(marker => ({
                ...marker,
                isHighlighted: claims.some(claim => `${claim.projAddr} ${claim.projCity}` === marker.address)
            }));
            setMarkers(updatedMarkers);
            setMainAddress(updatedMarkers[0]);
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    }, [client, filterForm, mainAddress, fileUploaded, t, markers, setMarkers]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowActiveJobs(event.target.checked);
    };

    const handleItemClick = (marker: FileClaim) => {
        setSelectedLocation(marker);
    };

    const handleConfirmMainAddress = () => {
        if (markerToConfirm) {
            setMainAddress(markerToConfirm);
            const updatedMarkers = markers.filter(marker => marker !== markerToConfirm);
            updatedMarkers.unshift(markerToConfirm);
            setMarkers(updatedMarkers);
            setPaginatedMarkers(updatedMarkers.slice(0, BATCH_SIZE));
            setMarkerToConfirm(null);
        }
    };

    const applyFilter = async () => {
        if (!mainAddress) {
            toast.error(t('errorMainAddressRequired'));
            return;
        };

        const isRegionOnlySelected =
        !isEmpty(filterForm.selectedRegion) && 
        isEmpty(filterForm.PM) &&
        isEmpty(filterForm.companies) &&
        isEmpty(filterForm.branches) &&
        isEmpty(filterForm.phases);

        if(isRegionOnlySelected) {
            setInvalidFilter(true);
            return;
        }

        setInvalidFilter(false);

        try {
            setIsLoading(true);

            const response: AxiosResponse<Claim[]> = await client.post(
                `/common/claims/search/address?postal=${mainAddress.postal}&latitude=${mainAddress?.lat}&longitude=${mainAddress?.lng}&distance=${filterForm.selectedDistance}`,
                {
                    ...filterForm,
                }
            );
            const claims = response.data;
            setClaimMarkers(claims);
    
            // Highlight items in paginatedMarkers that are also in claimMarkers
            const updatedPaginatedMarkers = paginatedMarkers.map(marker => ({
                ...marker,
                isHighlighted: claims.some(claim => claim.lossAddressLat === marker.lat && claim.lossAddressLon === marker.lng)
            }));
            setPaginatedMarkers(updatedPaginatedMarkers);

            // Close the modal programmatically
            const modalElement = document.getElementById('filterModal');
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            modalInstance.hide();
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };

    const clearFilter = async () => {        
        const resetFilters = {...filterForm,
            startDate: undefined,
            endDate: undefined,
            selectedRegion: null,
            cat: undefined,
            PM: undefined,
            branches: [] as Branch[],
            companies: [] as InsuranceCompany[],
            phases: [] as PhaseOption[],
        };

        setFilterForm(resetFilters);
        setSelectedBranches([]);
        setSelectedPhases([]);
        setSelectedCompanies([]);
        setInvalidFilter(false);

        setIsLoading(true);
        
        try 
        {
            if(mainAddress && mainAddress.postal && filterForm.selectedDistance) 
            {
                const response: AxiosResponse<Claim[]> = await client.post(
                    `/common/claims/search/address?postal=${mainAddress.postal}&latitude=${mainAddress.lat}&longitude=${mainAddress.lng}&distance=${filterForm.selectedDistance}`,
                    {
                        ...resetFilters,
                    }
                );
                const claims = response.data;
                setClaimMarkers(claims);

                const updatedMarkers = markers.map(marker => ({
                    ...marker,
                    isHighlighted: claims.some(claim => `${claim.projAddr} ${claim.projCity}` === marker.address)
                }));
                setMarkers(updatedMarkers);
            }            
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (mainAddress && filterForm.selectedDistance) {
            const filtered = markers.filter(marker => {
                const distance = calculateDistance(mainAddress.lat, mainAddress.lng, marker.lat, marker.lng);
                return distance <= Number(filterForm.selectedDistance);
            });
            setFilteredMarkers(filtered);
            setCurrentPage(1);
        } else {
            setFilteredMarkers(markers);
        }
    }, [mainAddress, filterForm.selectedDistance, markers]);
    
    useEffect(() => {
        const paginated = filteredMarkers.slice((currentPage - 1) * BATCH_SIZE, currentPage * BATCH_SIZE);
        setPaginatedMarkers(paginated);
    }, [currentPage, filteredMarkers]);

    const totalPages = Math.ceil(filteredMarkers.length / BATCH_SIZE);

    return (
        <div className="p-3">
            <div className="d-flex align-items-center mb-2">
                <input
                    type="file"
                    className="form-control"
                    style={{ width: '40%', marginRight: '0.5rem' }}
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    onClick={(event: any)=> { 
                        event.target.value = null;
                   }}
                />
                <select
                    value={filterForm.selectedDistance}
                    onChange={handleDistanceChange}
                    className="form-control"
                    style={{ marginRight: '0.5rem', width: 'auto' }}
                >
                    <option value="">
                        {t('distance')}
                    </option>
                    {DISTANCES.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <select 
                    value={filterForm.lossCat ?? ''}
                    onChange={(e) => handleFormChange('lossCat', e.target.value)}
                    disabled={!mainAddress || !filterForm.selectedDistance}
                    className="form-control" 
                    style={{ marginRight: '0.5rem', width: 'auto' }}>
                    <option value="" disabled hidden>{t('lossCat')}</option>
                    {CLAIM_TYPES.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <div className="form-check ml-3" style={{ marginRight: '0.5rem' }}>
                    <label className="form-check-label" htmlFor="showActiveJobs">
                        {t('chkActiveJobs')}
                    </label>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="showActiveJobs"
                        checked={showActiveJobs}
                        onChange={handleCheckboxChange}
                    />
                </div>
                <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#filterModal">
                    <FaFilter />
                </button>
                <button className="btn btn-primary" onClick={() => handleSearchClick()}>
                    {t('searchBtn')}
                </button>
                {showActiveJobs && claimMarkers && claimMarkers?.length > 0 && <button className="btn btn-primary d-flex align-items-center" onClick={() => handleExportClick()}>
                    <FaFileExcel /> {t('export')}
                </button>}
                {/* Legend */}
                <div className="mb-3">
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ background: 'conic-gradient(#ea4335, #1a73e8, #4285f4, #34a853, #fbbc04)' }}></div>
                        <span className="ms-2">{t('mainAddress')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#40e451' }}></div>
                        <span className="ms-2">{t('fileClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#9166fb' }}></div>
                        <span className="ms-2">{t('ctClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#ff9015' }}></div>
                        <span className="ms-2">{t('cTInspectedClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <FaRegBuilding />
                        <span className="ms-2">{t('commercialClaims')}</span>
                    </div>
                </div>
            </div>            
            <div className="d-flex" style={{ height: 'calc(100vh - 70px)' }}>
                <div
                    style={{
                        flex: '1 1 20%',
                        paddingRight: '0.5rem',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 100px)',
                    }}
                >
                    {fileUploaded && <div className="alert alert-info" role="alert">
                        <small>{t('infoMessage')}</small>
                    </div>}
                    <nav>
                        <ul className="pagination">
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button onClick={() => handlePageChange(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="list-group">
                        {paginatedMarkers.map((marker, index) => (
                            <div
                                key={index}
                                className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
                                    marker.isHighlighted ? 'bg-warning text-dark' : ''
                                }`}
                                onClick={() => handleItemClick(marker)}
                            >
                                { marker === mainAddress ?
                                <img src={MarkerIcon} alt="Marker Icon" style={{ width: '20px', height: '20px' }} />:
                                    <FaMapMarkerAlt className='text-success' style={{ minWidth: '20px' }} /> }                                
                                <span className="flex-grow-1 mx-2 small">{marker.address}</span>
                                <FaCaretRight
                                    className="text-secondary"
                                    style={{ minWidth: '20px', cursor: 'pointer' }}
                                    onClick={() => setMarkerToConfirm(marker)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirmModal"
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ flex: '3 1 80%' }}>
                    {isLoading ? (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : mainAddress ? (
                        <Map
                            zoom={15}
                            fileMarkers={paginatedMarkers}
                            center={mainAddress}
                            selectedLocation={selectedLocation}
                            claimMarkers={showActiveJobs ? claimMarkers : undefined}
                        />
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src="assets/images/FirstOnsiteLogo.png" alt="No Search" />
                        </div>
                    )}
                </div>
            </div>

            <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmModalLabel">
                                {t('confirmTitle')}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {t('confirmMessage')} {selectedLocation?.address}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                {t('cancel')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleConfirmMainAddress} data-bs-dismiss="modal">
                                {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-3">
                <FilterModal                        
                        filterForm={filterForm}
                        onFilterChange={handleFormChange}
                        clearFilter={clearFilter}
                        applyFilter={applyFilter}
                        selectedBranches={selectedBranches}
                        setSelectedBranches={setSelectedBranches}
                        selectedPhases={selectedPhases}
                        setSelectedPhases={setSelectedPhases}
                        selectedCompanies={selectedCompanies}
                        setSelectedCompanies={setSelectedCompanies}
                        invalidFilter={invalidFilter}
                        setInvalidFilter={setInvalidFilter}
                    />
            </div>

            <ToastContainer />
        </div>
    );
};

export default BulkUploadTab;
